.login {
  background: #fff;

  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  &--left {
    padding:48px;

    width: 50vw;
    height: 100vh;

    &-content {
      height: inherit;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-end;
      align-content: center;

      .login--input, button {
        margin-bottom: 24px;
        width:320px;
      }
    }
  }

  &--right {
    padding:48px;
    background: #0f172a;
    width: 50vw;
    height: 100vh;
  }
}