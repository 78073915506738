.side-menu {
  margin-top: 48px !important;

  &--wrapper {
    background: #0f172a;
    height: 100vh;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    .menu-header {
      padding-top: 48px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;

      p {
        color: #fff;
        margin-top: 24px;
      }

      &--credentials {
        p {
          color: #0f172a;
          text-align: center;
          line-height: 96px;
          padding: 0;
          margin: 0;
          font-size: 24px;
          font-weight: 700;
        }

        background: #fff;
        border-radius: 50%;
        width: 96px;
        height: 96px;
      }
    }
  }

  li {
    cursor: pointer;

    width: calc(100% - 20px) !important;
    margin: 0 10px;
  }

  p, span {
    color: #9fa2aa;

    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  svg {
    color: #9fa2aa;
  }

  &--item-active {
    background: #2c3344;

    border-radius: 8px;

    span, p {
      color: #fff;
    }

    svg {
      color: #fff;
    }
  }
}